import React, {useContext} from "react"
import { css } from '@emotion/css'
import emailjs from 'emailjs-com';
import { navigate } from 'gatsby';
import Fade from 'react-reveal/Fade';

import Layout from "../shared/Layout2"
import SectionTitle from "../shared/SectionTitle"
import SEO from "../shared/seo"
import NavbarNew from "../shared/NavbarNew"
import { LangugaeContext } from "../../context/LanguageProvider";

const inputStyle = {
    width: '100%',
    height: '60px',
    border: 'none',
    background: 'white',
    color: 'rgb(32 32 32)',
    boxShadow: 'inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    padding: '0 20px',
    margin: '5px 0',
    marginBottom: '10px',
    fontSize: '24px',
    transition: 'all 0.4s ease',
    outline: 'none',
}

const messageForm = {
    width: '100%',
    height: '100%',
    minHeight: '250px',
    border: 'none',
    background: 'white',
    color: 'rgb(32 32 32)',
    boxShadow: 'inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    padding: '10px 20px',
    margin: '5px 0',
    fontSize: '24px',
    transition: 'all 0.4s ease',
    outline: 'none',
}

const label = {
    color: 'var(--darkGray)',
    fontSize: '16px',
}

export default function ContactComponent({cmsData}) {

    const language = useContext(LangugaeContext);

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_qz0pi9q', 'template_o9ihdgl', e.target, 'user_TTFSyPktdTdolaA5oZH9E')
            .then((result) => {
                console.log(result.text);
                //navigate('/success/')
                language.constructPath("success", language);

            }, (error) => {
                console.log(error.text);
                //navigate('/failed/')
                language.constructPath("failed", language);
            });
    }

    return (

        <Layout>
            <NavbarNew darkMode />
            <SEO title="Kontakt" />

            <div
                className={css`
                    height: 1000px;
                    background-image: url(${cmsData.bg.childImageSharp.fluid.src});
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                        
                    @media (max-width: 1000px) {
                        height: 1100px;
                    }
                `}
            >

                <div className={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background: rgb(255,255,255);
                    background: linear-gradient(180deg, rgba(255,255,255,0.1801764651172969) 0%, rgba(255,255,255,0.7123893502713585) 22%, rgba(255,255,255,1) 73%);
                `}>
                    <form
                        onSubmit={sendEmail}
                        className={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 80%;
                        max-width: 1000px;
                        border-radius: 20px;
                        padding: 40px;
                        background: var(--lightGrey);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 23px 8px;
                    
                        @media (max-width: 1000px) {
                            width: 100%;
                            
                            border-radius: 0px;
                            padding: 90px 10px;
                            padding-top: 150px;
                            height: 100%;
                        }
                    `}
                    >
                        <SectionTitle text={cmsData.title} />

                        <Fade>
                            <div
                                className={css`
                            display: flex;
                            text-align: left;
                            margin-bottom: 30px;
                            font-size: 20px;
                            width: 100%;
                            height: 100%;
                        
                            @media (max-width: 1000px) {
                                flex-direction: column;
                            }
                        `}>

                                <div
                                    className={css`
                            display: flex;
                            flex-direction: column;
                            margin: 0px 15px;
                            flex-grow: 1;
                            
                            @media (max-width: 1000px) {
                                flex-grow: 0;
                            }
                        `}

                                >

                                    <label style={label}>{cmsData.emailForm.nameField}</label>
                                    <input style={inputStyle} type="text" name="user_firstname" />

                                    {/*<label style={label}><FormattedMessage
                                        id="contanct_title02"
                                        defaultMessage="Efternamn"
                                    /></label>
                        <input style={inputStyle} type="text" name="user_lastname" />*/}

                                    <label style={label}>{cmsData.emailForm.emailField}</label>
                                    <input style={inputStyle} type="email" name="user_email" required />

                                   {/* <label style={label}><FormattedMessage
                                        id="contanct_title04"
                                        defaultMessage="Företag"
                                    /></label>
                                    <input style={inputStyle} type="text" name="user_business" />*/}

                                    <label style={label}>{cmsData.emailForm.phoneField}</label>
                                    <input style={inputStyle} type="number" name="user_phone" />

                                </div>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '0 15px',
                                    flexGrow: 1,
                                }}>
                                    <label style={label}>{cmsData.emailForm.messageField}</label>
                                    <textarea style={messageForm} name="message" />
                                </div>
                            </div>
                        </Fade>


                        <input className={css`
                            background: #5dbada;
                            font-weight: bold;
                            color: white;
                            width: 200px;
                            border: none;
                            height: 50px;
                            border-radius: 80px;
                            outline: none;
                            cursor: pointer;
                            @media (max-width: 565px) {
                                font-size: 16px;
                            }
                            `}
                            type="submit" id="submit-contact-form-button" value={cmsData.emailForm.buttonText} />
                    </form>
                </div>
            </div>

        </Layout >
    )
}
